import React from "react";
import { Card, Image, Text } from "@mantine/core";

export function LinkedInCard({
  image,
  logo,
  link,
  company,
  title,
  startDate,
  endDate,
  description,
}) {
  return (
    <div className="card-md">
      <Card shadow="sm" p="xs" radius="md" withBorder>
        {image && (
          <Card.Section>
            <Image
              src={image}
              radius="md"
              height={160}
              fit="contain"
              alt={company}
              imageProps={{
                loading: "lazy",
              }}
            />
          </Card.Section>
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ paddingRight: "8px" }}>
            <Image
              src={logo}
              radius="md"
              height={62}
              width={62}
              alt={company}
              component="a"
              href={link}
              target="_blank"
              withPlaceholder
              imageProps={{
                loading: "lazy",
              }}
            />
          </div>
          <div>
            <Text weight={500} style={{ lineHeight: "1.2em" }}>
              {title}
            </Text>
            <Text color="dimmed">{company}</Text>
            {startDate && endDate && (
              <Text size="sm" color="dimmed">
                {startDate} - {endDate}
              </Text>
            )}
          </div>
        </div>
        <div style={{ height: "5px" }}></div>
        <Text size="sm" color="dimmed" style={{ whiteSpace: "pre-line" }}>
          {description}
        </Text>
      </Card>
    </div>
  );
}
