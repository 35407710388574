import { Skeleton, Card } from "@mantine/core";

export function SkeletonCard() {
  return (
    <Card style={{ minWidth: "350px" }}>
      <Skeleton height={50} circle mb="xl" />
      <Skeleton height={8} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />
      <Skeleton height={8} mt={6} width="70%" radius="xl" />
      <Skeleton height={150} width={300} radius="md" m={10} />
    </Card>
  );
}
