import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

export function NavIcon({
  icon,
  iconBackground,
  iconColor,
  iconSize,
  hashRoute,
  isActive,
}) {
  return (
    <div className={isActive ? "active-icon" : undefined}>
      <Link
        to={`/#${hashRoute}`}
        style={{
          textDecoration: "none",
          color: "inherit",
          padding: "0px",
          margin: "0px",
          border: "none",
          // lineHeight: "1em",
        }}
      >
        {icon.type === "fa" ? (
          iconBackground ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "25px",
                // height: "60px",
              }}
            >
              <FontAwesomeIcon icon={faSquare} color="white" size="lg" />
              <FontAwesomeIcon
                icon={icon.icon}
                color={iconColor}
                size={iconSize}
                style={{
                  position: "absolute",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "25px",
                // height: "60px",
              }}
            >
              <FontAwesomeIcon
                icon={icon.icon}
                color={iconColor}
                size={iconSize}
                // className="white-background"
                // style={{ padding: "0px 8px"
                //  }}
              />
            </div>
          )
        ) : (
          icon.type === "emoji" && (
            <span
              // className="emoji-icon-white"
              className="emoji-icon-sm"
            >
              {icon.icon}
            </span>
          )
        )}
      </Link>
    </div>
  );
}
