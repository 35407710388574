import { useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";

export function ScrollToHashElement() {
  let location = useLocation();
  // console.log(location);

  let hashElement = useMemo(() => {
    let hash = location.hash;
    // console.log(hash);
    const removeHashCharacter = (str) => {
      const result = str.slice(1);
      // console.log(result);
      return result;
    };

    if (hash) {
      let element = document.getElementById(removeHashCharacter(hash));
      // console.log(element);
      return element;
    } else {
      return null;
    }
  }, [location]);

  useEffect(() => {
    if (hashElement) {
      hashElement.scrollIntoView({
        behavior: "smooth",
        // block: "end",
        offset: { top: 50, left: 0 },
      });
      // console.log(hashElement);
    }
  }, [hashElement]);

  return null;
}
