import React from "react";
import { Image, Card, Center } from "@mantine/core";

export function Polaroid({ image, description, emoji }) {
  return (
    <div className="card-polaroid">
      <Card shadow="sm" p="xs" radius={0}>
        <Card.Section
          style={{
            backgroundColor: "black",
            // maxHeight: "370px",
            margin: "10px",
            height: "290px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Center>
            <Image
              src={image}
              fit="contain"
              alt={description}
              withPlaceholder
              height={290}
              imageProps={{
                loading: "lazy",
              }}
              style={{
                maxWidth: "300px",
              }}
            />
          </Center>
        </Card.Section>

        {/* <div
          style={{
            textAlign: "center",
            whiteSpace: "pre-line",
            //Change back to the font family new hand
            fontFamily: "Just Another Hand",

            color: "#001680",
            lineHeight: "2em",
          }}
        > */}
        <Card.Section>
          <Center>
            <span>📍</span>
            <span
              style={{
                fontFamily: "Just Another Hand",
                color: "#001680",
                fontSize: "1.3em",
              }}
            >
              {description}
            </span>
            <span>&nbsp;{emoji}</span>
          </Center>
        </Card.Section>

        {/* </div> */}
      </Card>
    </div>
  );
}
