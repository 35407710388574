import React, { useState, useMemo, Suspense } from "react";
import {
  // BackgroundImage,
  // Box,
  Card,
  Group,
  ScrollArea,
  Text,
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function StripDouble({
  id,
  nav,
  startColor,
  endColor,
  title,
  title2,
  titleColor,
  followButton,
  explainer,
  icon,
  iconColor,
  iconLink,
  children,
}) {
  // Get Experience and Education children
  const experienceChildren = React.Children.toArray(children).filter(
    (child) => child.props.type === "Experience"
  );
  const educationChildren = React.Children.toArray(children).filter(
    (child) => child.props.type === "Education"
  );

  return (
    <div
      id={id}
      style={{
        scrollMarginTop: "65px",
      }}
    >
      <Card
        // shadow="sm"
        p="xs"
        radius="md"
        // withBorder
        m="md"
        sx={(theme) => ({
          backgroundImage: theme.fn.gradient({
            from: startColor,
            to: endColor,
            deg: 90,
          }),
          color: theme.white,
          boxShadow:
            "0 4px 12px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, 0.2)",
          // backgroundColor: "transparent", MINIMAL
          padding: "15px",
          display: "flex",
          flexDirection: "column",
          minHeight: "200px",
        })}
      >
        <Group position="apart">
          <Text
            size={28}
            color={titleColor ? titleColor : "white"} //COlOR
            // color={titleColor ? titleColor : "black"} //MINIMAL
            weight={500}
            p={0}
            // style={{ textShadow: "0em 0em 0.2em #FFFFFF" }}
          >
            {title || title2}
          </Text>
          {/* {showFollowButton && followButton} */}
          {/* <div className="icon-container"> */}
          {iconLink ? (
            <a href={iconLink} target="_blank" rel="noreferrer">
              {icon?.type === "fa" && (
                <FontAwesomeIcon
                  icon={icon.icon}
                  color={iconColor} //COLOR
                  // color={startColor} //MINIMAL
                  size="2x"
                /> //CHANGE THIS BACK
              )}
              {icon?.type === "emoji" && (
                <span className="emoji-icon">{icon.icon}</span>
              )}
            </a>
          ) : (
            <>
              {icon?.type === "fa" && (
                <FontAwesomeIcon
                  icon={icon.icon}
                  color={iconColor} //COLOR
                  // color={startColor} //MINIMAL
                  size="2x"
                /> //CHANGE THIS BACK
              )}
              {icon?.type === "emoji" && (
                <span className="emoji-icon">{icon.icon}</span>
              )}
            </>
          )}
          {/* </div> */}
        </Group>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            color: titleColor,
          }}
        >
          {explainer}
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          <ScrollArea type="hover" offsetScrollbars>
            <div className="card-container-scroll-1">
              {experienceChildren?.length > 0
                ? experienceChildren
                : educationChildren}
            </div>
          </ScrollArea>
        </Suspense>
        {title && (
          <>
            <Text
              size={28}
              // color={titleColor ? titleColor : "#191919"}
              color={titleColor ? titleColor : "white"} //COLOR
              // color={titleColor ? titleColor : "black"} //MINIMAL
              weight={500}
              p={0}
              // style={{ textShadow: "0em 0em 0.2em #FFFFFF" }}
            >
              {title2}
            </Text>
            <Suspense fallback={<div>Loading...</div>}>
              <ScrollArea type="hover" offsetScrollbars>
                <div className="card-container-scroll-1">
                  {educationChildren}
                </div>
              </ScrollArea>
            </Suspense>
          </>
        )}
      </Card>
    </div>
  );
}
