import React from "react";
import { Card, Image, Text, Rating } from "@mantine/core";

export function GoodreadsCard({ image, title, author, rating }) {
  return (
    <div className="card-book">
      <Card shadow="sm" p="xs" radius="md" withBorder>
        <div style={{ display: "flex", alignItems: "center", height: "100px" }}>
          <Image
            src={image}
            width={60}
            height={100}
            radius="md"
            // fit="contain"
            alt={title}
            withPlaceholder
            imageProps={{
              loading: "lazy",
            }}
          />
          <div style={{ paddingLeft: "10px" }}>
            <Text weight={500} lineClamp={2}>
              {title}
            </Text>
            <Text size="sm" color="dimmed" lineClamp={2}>
              by {author}
            </Text>
            {rating && (
              <div style={{ paddingTop: "5px" }}>
                <Rating color="orange" value={rating} readOnly />
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}
