import "./App.css";
import { useState, Suspense, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Affix,
  AppShell,
  Header,
  Button,
  Footer,
  Flex,
  Kbd,
  Text,
  useMantineTheme,
  MantineProvider,
  Paper,
  ScrollArea,
} from "@mantine/core";
import {
  NotificationsProvider,
  // showNotification,
} from "@mantine/notifications";
// import Plausible from "plausible-tracker";
import LogRocket from "logrocket";
// import { Carousel } from "@mantine/carousel";
import { useLocalStorage, useMediaQuery } from "@mantine/hooks";
// import { InlineWidget } from "react-calendly";
import { ProjectCard } from "./components/ProjectCard";
import { LinkedInCard } from "./components/LinkedInCard";
import { Polaroid } from "./components/Polaroid";
import { GoodreadsCard } from "./components/GoodreadsCard";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { StripDouble } from "./components/StripDouble";
// import { ContactCard } from "./components/ContactCard";

import { Strip } from "./components/Strip";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAirbnb,
  faSpotify,
  // faLinkedin,
  // faFacebook,
  faGoodreads,
  // faSubstack,
  faGithub,
  faYoutube,
  // faImdb,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { SpotifyCard } from "./components/SpotifyCard";
import { YouTubeCard } from "./components/YouTubeCard";
import { WritingCard } from "./components/WritingCard";
import { GitHubLanguageIcons } from "./components/GitHubLanguageIcon";

import {
  user,
  aboutBullets,
  // portfolioData,
  gitHubData,
  jobData,
  educationData,
  // excelData,
  // powerPointData,
  goodreadsData,
  podcastData,
  musicData,
  youTubeData,
  polaroidData,
  articlesData,
  contactData,
  twitterData,
  calendarData,
  airbnbData,
  colors,
  // movieData,
  // contactReasons,
  // emailDefaults,
} from "./data";
// import { ExcelCard } from "./components/ExcelCard";
import { EmailCard } from "./components/EmailCard";
// import { RoleRadio } from "./components/RoleRadio";
import { LeaveModal } from "./components/LeaveModal";
// import { MovieCard } from "./components/MovieCard";
import { CalendarCard } from "./components/CalendarCard";
import { ScrollToHashElement } from "./components/ScrollToHashElement";
import { Tweet } from "./components/Tweet";
import { NavIcon } from "./components/NavIcon";
import { CommitCalendar } from "./components/CommitCalendar";
import { AboutStrip } from "./components/AboutStrip";
import { AirbnbCard } from "./components/AirbnbCard";

export const emojiClueBlue = "#0DAEFF";

// const { trackEvent } = Plausible();
LogRocket.init("akxyos/aubbrey");
export const ownerFirstName = "Aubrey";
export const owner = {
  fullName: "Aubrey Threadgill",
  firstName: "Aubrey",
  lastName: "Threadgill",
  email: "aubrey.threadgill@gmail.com",
};

export default function App() {
  const theme = useMantineTheme();
  // const [opened, setOpened] = useState(false);
  const [radioValue, setRadioValue] = useState("Investor");
  const [leaveModalOpened, setLeaveModalOpened] = useState(false);
  const [isDismissed, setIsDismissed] = useLocalStorage("isDismissed", false);
  const isTouch = "ontouchstart" in window || navigator.msMaxTouchPoints;
  const [activeSection, setActiveSection] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);

  const isLargerThanSm = useMediaQuery("(min-width: 48em)");

  const sections = [
    "github",
    "linkedin",
    "goodreads",
    "articles",
    "spotify",
    "youtube",
    "twitter",
    "photos",
    "airbnb",
    "events",
    "contact",
  ]; // List of section ids

  const handleScroll = () => {
    const sectionPositions = sections.map((id) => {
      const element = document.getElementById(id);
      return element.getBoundingClientRect().top;
    });

    const OFFSET = -180;

    const currentSection = sections.find(
      (_, index) => sectionPositions[index] >= 0 + OFFSET
    );
    setActiveSection(currentSection);

    const currentScrollPosition = window.pageXOffset;
    setScrollPosition(currentScrollPosition);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleRedirect = () => {
      window.location.href = 'https://tapistr.ee/aubrey'; // Replace with the target URL
    };

    handleRedirect();

  }, [])

  return (
    <div className="App">
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          breakpoints: {
            xs: "30em",
            sm: "48em",
            md: "64em",
            lg: "74em",
            xl: "90em",
          },
        }}
      >
        <NotificationsProvider position="top-right">
          <AppShell
            styles={{
              main: {
                background:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : "#FAF9FC",
                // : theme.colors.blue[5],
                padding: "50px 0px 30px 0px",
              },
            }}
            footer={
              <Footer height={30} color="gray">
                {/* <div
                  style={{
                    display: "flex",
                    height: "30px",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                > */}
                <Text size="xs" p="xs">
                  Want your own site? Message me and I'll build it.
                </Text>
                {/* </div> */}
              </Footer>
            }
            header={
              <Header
                height={50}
                mt={10}
                style={{
                  maxWidth: "850px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "50px",
                    padding: "0px 10px",
                    borderRadius: "24px",
                    backgroundColor: "#202123",
                    color: theme.white,
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    border: "none",
                    // maxWidth: "1200px",
                    marginLeft: "16px",
                    marginRight: "16px",
                  }}
                >
                  {isLargerThanSm && (
                    <Flex m="sm">
                      <Text size="md" weight={600} mr={10}>
                        {user}
                      </Text>
                    </Flex>
                  )}
                  <ScrollArea type="never">
                    <Flex
                      gap="xs"
                      align="center"
                      justify="center"
                      px={8}
                      style={{
                        flexGrow: 1,
                        minHeight: "30px",
                      }}
                    >
                      {/* <NavIcon
                      icon={{ type: "fa", icon: faUser }}
                      iconColor="white"
                      iconSize="xl"
                      hashRoute="github"
                    /> */}
                      <NavIcon
                        icon={{ type: "fa", icon: faGithub }}
                        iconColor="white"
                        iconSize="xl"
                        hashRoute="github"
                        isActive={activeSection === "github"}
                      />
                      <NavIcon
                        icon={{ type: "fa", icon: faLinkedin }}
                        iconColor={colors.linkedin}
                        iconSize="xl"
                        hashRoute="linkedin"
                        iconBackground
                        isActive={activeSection === "linkedin"}
                      />
                      {/* <NavIcon
                      icon={{ type: "fa", icon: faFilePowerpoint }}
                      iconColor="white"
                      iconSize="xl"
                      hashRoute="powerpoint"
                    />
                    <NavIcon
                      icon={{ type: "fa", icon: faFileExcel }}
                      iconColor="white"
                      iconSize="xl"
                      hashRoute="excel"
                    /> */}
                      <NavIcon
                        icon={{ type: "fa", icon: faGoodreads }}
                        iconColor={colors.goodreads}
                        iconSize="xl"
                        hashRoute="goodreads"
                        isActive={activeSection === "goodreads"}
                      />
                      <NavIcon
                        icon={{ type: "emoji", icon: "👓" }}
                        // iconColor="white"
                        iconSize="xl"
                        hashRoute="articles"
                        isActive={activeSection === "articles"}
                      />
                      <NavIcon
                        icon={{ type: "fa", icon: faSpotify }}
                        iconColor={colors.spotify}
                        iconSize="xl"
                        hashRoute="spotify"
                        iconBackground
                        isActive={activeSection === "spotify"}
                      />
                      <NavIcon
                        icon={{ type: "fa", icon: faYoutube }}
                        iconColor={colors.youtube}
                        iconSize="xl"
                        hashRoute="youtube"
                        iconBackground
                        isActive={activeSection === "youtube"}
                      />
                      <NavIcon
                        icon={{ type: "fa", icon: faTwitter }}
                        iconColor={colors.twitter}
                        iconSize="xl"
                        hashRoute="twitter"
                        isActive={activeSection === "twitter"}
                      />
                      <NavIcon
                        icon={{ type: "emoji", icon: "📸" }}
                        // iconColor="white"
                        iconSize="xl"
                        hashRoute="photos"
                        isActive={activeSection === "photos"}
                      />
                      <NavIcon
                        icon={{ type: "fa", icon: faAirbnb }}
                        iconColor={colors.airbnb}
                        iconSize="xl"
                        hashRoute="airbnb"
                        isActive={activeSection === "airbnb"}
                      />
                      <NavIcon
                        icon={{ type: "emoji", icon: "📆" }}
                        // iconColor="white"
                        iconSize="xl"
                        hashRoute="events"
                        isActive={activeSection === "events"}
                      />
                    </Flex>
                  </ScrollArea>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // margin: "0px 10px",
                    }}
                  >
                    {isLargerThanSm ? (
                      <Link
                        to={`/#contact`}
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          padding: "0px",
                          margin: "0px",
                          border: "none",
                          // lineHeight: "1em",
                        }}
                      >
                        <Flex>
                          <Text size="md" color="white" weight={500}>
                            Get in Touch
                          </Text>
                          <span className="emoji-icon-sm">👋</span>
                        </Flex>
                      </Link>
                    ) : (
                      <NavIcon
                        icon={{ type: "emoji", icon: "👋" }}
                        iconColor="white"
                        iconSize="xl"
                        hashRoute="contact"
                      />
                    )}
                  </div>
                </div>
              </Header>
            }
          >
            <div className="body">
              {!isTouch && !isDismissed && (
                <Affix position={{ bottom: 30, right: 20 }}>
                  <Paper p="sm" shadow="sm">
                    <Flex>
                      <Text size="sm">
                        Hold <Kbd>⇧ Shift</Kbd> while scrolling to move left to
                        right.
                      </Text>
                      <Button
                        size="sm"
                        ml={4}
                        compact
                        onClick={() => setIsDismissed(true)}
                      >
                        Dismiss
                      </Button>
                    </Flex>
                  </Paper>
                </Affix>
              )}
              <LeaveModal
                leaveModalOpened={leaveModalOpened}
                setLeaveModalOpened={setLeaveModalOpened}
              />
              <Suspense fallback={<div>Loading...</div>}>
                {aboutBullets.length > 0 && (
                  <AboutStrip
                    id="about"
                    nav="scroll"
                    startColor="#099268"
                    endColor="#12B886"
                    title="About"
                    titleColor="#FFFFFF"
                    icon={{ type: "emoji", icon: "👨‍💻" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        // color: "#191919",
                        color: "#FFFFFF",
                      }}
                    >
                      {aboutBullets.map((bullet) => {
                        return (
                          <>
                            <span>{bullet}</span>
                          </>
                        );
                      })}
                    </div>
                  </AboutStrip>
                )}
              </Suspense>
              <Suspense fallback={<div>Loading...</div>}>
                {gitHubData.length > 0 && (
                  <Strip
                    id="projects"
                    nav="scroll"
                    startColor="#019DFC"
                    endColor="#1174DB"
                    title="Projects"
                    icon={{ type: "emoji", icon: "🛠️" }}
                    iconColor="white"
                  >
                    {gitHubData.map((item, index) => {
                      return (
                        <ProjectCard
                          key={index}
                          images={item.images}
                          backgroundColor={item.backgroundColor}
                          title={item.title}
                          description={item.description}
                          status={item.status}
                          link={item.link}
                          callToAction={item.callToAction}
                        />
                      );
                    })}
                  </Strip>
                )}
              </Suspense>
              <Suspense fallback={<div>Loading...</div>}>
                {gitHubData.length > 0 && (
                  <Strip
                    id="github"
                    nav="scroll"
                    startColor="#24292F"
                    endColor="#24292F"
                    title="Dev"
                    icon={{ type: "fa", icon: faGithub }}
                    iconColor="white"
                    iconLink="https://github.com/aubreythreadgill/"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        // minHeight: "400px",
                        // color: "#191919",
                        color: "white",
                      }}
                    >
                      <div style={{ marginLeft: "10px" }}>
                        <GitHubLanguageIcons
                          languages={["JavaScript", "HTML", "CSS"]} //Make dynamic
                        />
                        <div style={{ height: "20px" }} />
                        <ErrorBoundary>
                          <CommitCalendar
                            username="aubreythreadgill" //Make dynamic
                          />
                        </ErrorBoundary>
                      </div>
                    </div>
                  </Strip>
                )}
              </Suspense>
              <Suspense fallback={<div>Loading...</div>}>
                {(jobData.length > 0 || educationData.length) && (
                  <>
                    <StripDouble
                      id="linkedin"
                      nav="scroll"
                      startColor="#0A66C2"
                      endColor="#0A66C2"
                      title={jobData.length > 0 && "Experience"}
                      title2={educationData.length > 0 && "Education"}
                      icon={{ type: "fa", icon: faLinkedin }}
                      iconLink="https://www.linkedin.com/in/aubreythreadgill/"
                      iconColor="white"
                      // iconColor="#0A66C2"
                    >
                      {jobData?.length > 0 &&
                        jobData?.map((item, index) => {
                          return (
                            <LinkedInCard
                              key={index}
                              type="Experience"
                              image={item.image}
                              logo={item.logo}
                              company={item.company}
                              title={item.title}
                              location={item.location}
                              description={item.description}
                              link={item.link}
                              startDate={item.startDate}
                              endDate={item.endDate}
                            />
                          );
                        })}
                      {educationData?.length > 0 &&
                        educationData?.map((item, index) => {
                          return (
                            <LinkedInCard
                              key={index}
                              type="Education"
                              image={item.image}
                              logo={item.logo}
                              company={item.company}
                              title={item.title}
                              location={item.location}
                              description={item.description}
                              link={item.link}
                              startDate={item.startDate}
                              endDate={item.endDate}
                            />
                          );
                        })}
                    </StripDouble>
                  </>
                )}
              </Suspense>
              {/* <Suspense fallback={<div>Loading...</div>}>
                {excelData.length > 0 && (
                  <Strip
                    id="excel"
                    nav="scroll"
                    startColor="#138146"
                    endColor="#138146"
                    title="Excel"
                    icon={{ type: "fa", icon: faFileExcel }}
                    // iconColor="#138146"
                    iconColor="white"
                  >
                    {excelData ? (
                      excelData.map((item) => {
                        return (
                          // <Carousel.Slide>
                          <ErrorBoundary>
                            <ExcelCard
                              link={item.link}
                              title={item.title}
                              description={item.description}
                            />
                          </ErrorBoundary>
                          /* </Carousel.Slide> */}
              {/* //           );
              //         })
              //       ) : (
              //         <Skeleton />
              //       )}
              //     </Strip>
              //   )}
              // </Suspense>  */}
              <Suspense fallback={<div>Loading...</div>}>
                {goodreadsData.length > 0 && (
                  <Strip
                    id="goodreads"
                    nav="scroll1"
                    startColor="#D6D0C4"
                    endColor="#D6D0C4"
                    title="Bookshelf"
                    titleColor="#372213"
                    icon={{ type: "fa", icon: faGoodreads }}
                    iconColor="#372213"
                    explainer="A few of my favorite books"
                  >
                    {goodreadsData.map((item) => {
                      return (
                        <GoodreadsCard
                          image={item.image}
                          title={item.title}
                          author={item.author}
                          rating={item.rating}
                          description={item.description}
                        />
                      );
                    })}
                  </Strip>
                )}
              </Suspense>
              <Suspense fallback={<div>Loading...</div>}>
                {articlesData.length > 0 && (
                  <Strip
                    id="articles"
                    nav="scroll1"
                    startColor="#0B7285"
                    endColor="#0B7285"
                    title="Writing"
                    icon={{ type: "emoji", icon: "👓" }}
                    iconColor="white"
                    explainer="A few of my favorite essays"
                  >
                    {articlesData.map((item) => {
                      return (
                        <WritingCard
                          image={item.image}
                          title={item.title}
                          author={item.author}
                          description={item.description}
                          link={item.link}
                          favicon={item.favicon}
                        />
                      );
                    })}
                  </Strip>
                )}
              </Suspense>
              <Suspense fallback={<div>Loading...</div>}>
                {(musicData.length > 0 || podcastData.length) && (
                  <>
                    {podcastData.length > 0 && (
                      <Strip
                        id="spotify"
                        nav="scroll"
                        startColor="#121212"
                        endColor="#121212"
                        title="Podcasts & Music"
                        icon={{ type: "fa", icon: faSpotify }}
                        iconColor="#45DB74"
                        explainer="A few of my favorite pods and albums"
                      >
                        {podcastData.map((item, index) => {
                          return (
                            <SpotifyCard
                              key={index}
                              image={item.image}
                              title={item.title}
                              author={item.author}
                              link={item.link}
                              tall={true}
                            />
                          );
                        })}
                        {musicData.map((item, index) => {
                          return (
                            <SpotifyCard
                              key={index}
                              image={item.image}
                              title={item.title}
                              author={item.author}
                              link={item.link}
                              tall={true}
                            />
                          );
                        })}
                      </Strip>
                    )}
                    {/* {musicData.length > 0 && (
                      <Strip
                        nav="scroll"
                        startColor="#45DB74"
                        endColor="#45DB74"
                        title="Music"
                        icon={{ type: "", icon: "" }}
                        iconColor="white"
                      >
                        {musicData.map((item) => {
                          return (
                            <SpotifyCard
                              image={item.image}
                              title={item.title}
                              author={item.author}
                              link={item.link}
                            />
                          );
                        })}
                      </Strip>
                    )} */}
                  </>
                )}
              </Suspense>
              <Suspense fallback={<div>Loading...</div>}>
                {youTubeData.length > 0 && (
                  <Strip
                    id="youtube"
                    nav="scroll"
                    startColor="#FF0000"
                    endColor="#FF0000"
                    title="Videos"
                    icon={{ type: "fa", icon: faYoutube }}
                    // iconColor="#FF0000"
                    iconColor="white"
                    explainer="A few of my favorite videos."
                  >
                    {youTubeData.map((item) => {
                      return (
                        // <Carousel.Slide>
                        <YouTubeCard youTubeUrl={item.link} />
                        // </Carousel.Slide>
                      );
                    })}
                  </Strip>
                )}
              </Suspense>
              <Suspense fallback={<div>Loading...</div>}>
                {twitterData.length > 0 && (
                  <Strip
                    id="twitter"
                    nav="scroll"
                    startColor="#1D9BF0"
                    endColor="#1D9BF0"
                    title="Tweets"
                    icon={{ type: "fa", icon: faTwitter }}
                    // iconColor="#1D9BF0"
                    iconColor="white"
                    iconLink="https://twitter.com/aubthread"
                    explainer="A few of my favorite tweets."
                  >
                    {twitterData.map((item) => {
                      return <Tweet tweetId={item.tweetId} />;
                    })}
                  </Strip>
                )}
              </Suspense>
              {/* {movieData.length > 0 && (
              <Strip
              id="movies"
                nav="scroll"
                startColor="#F5C518"
                endColor="#F5C518"
                title="Movies"
                icon={{ type: "emoji", icon: "📺" }}
                iconColor="white"
              >
                {movieData.map((item) => {
                  return (
                    <MovieCard
                      link={item.link}
                      image={item.image}
                      title={item.title}
                      rating={item.rating}
                    />
                  );
                })}
              </Strip>
            )} */}
              <Suspense fallback={<div>Loading...</div>}>
                {polaroidData.length > 0 && (
                  <Strip
                    id="photos"
                    nav="scroll"
                    startColor="#EEE1C3"
                    endColor="#EEE1C3"
                    title="Photos"
                    icon={{ type: "emoji", icon: "📸" }}
                    iconColor="white"
                  >
                    {polaroidData.map((item) => {
                      return (
                        // <Carousel.Slide>
                        <Polaroid
                          image={item.image}
                          description={item.description}
                          emoji={item.emoji}
                        />
                        // </Carousel.Slide>
                      );
                    })}
                  </Strip>
                )}
              </Suspense>
              <Suspense fallback={<div>Loading...</div>}>
                {airbnbData.length > 0 && (
                  <Strip
                    id="airbnb"
                    nav="scroll"
                    startColor="#FF385C"
                    endColor="#FF385C"
                    // startColor="#ED6666"
                    // endColor="#EE638B"
                    title="Airbnb"
                    icon={{ type: "fa", icon: faAirbnb }}
                    iconColor="white"
                    explainer="A few of my favorite stays"
                  >
                    {airbnbData.map((item) => {
                      return (
                        // <Carousel.Slide>
                        <AirbnbCard url={item.url} key={item.url} />
                        // </Carousel.Slide>
                      );
                    })}
                  </Strip>
                )}
              </Suspense>
              <Suspense fallback={<div>Loading...</div>}>
                {calendarData.length > 0 && (
                  <Strip
                    id="events"
                    nav="scroll"
                    startColor="indigo"
                    endColor="indigo"
                    title="Events"
                    icon={{ type: "emoji", icon: "📅" }}
                    iconColor="white"
                  >
                    {calendarData
                      // .filter((item) => new Date(item.date) >= new Date())
                      // .sort((a, b) => new Date(a.date) - new Date(b.date))
                      .map((item) => {
                        return (
                          // <Carousel.Slide>
                          <CalendarCard
                            event={item.event}
                            date={item.date}
                            address={item.address}
                          />
                          /* </Carousel.Slide> */
                        );
                      })}
                  </Strip>
                )}
              </Suspense>
              <Suspense id="contact" fallback={<div>Loading...</div>}>
                {contactData.length > 0 && (
                  <Strip
                    id="contact"
                    nav="scroll"
                    startColor="#F8F9FA"
                    endColor="#F8F9FA"
                    title="Get in Touch"
                    titleColor="blue"
                    explainer=""
                    icon={{ type: "emoji", icon: "👋" }}
                    iconColor="white"
                  >
                    <div className="card-lg">
                      <EmailCard
                        radioValue={radioValue}
                        setRadioValue={setRadioValue}
                        yourEmail="aubrey.threadgill@gmail.com"
                      />
                    </div>
                    {/* <div className="card-lg" id="contact">
                      <a
                        href="#contact"
                        aria-label="contact"
                        aria-hidden="true"
                      >
                        <Card
                          shadow="sm"
                          p="xs"
                          radius="md"
                          withBorder
                          style={{ maxHeight: "460px", minWidth: "300px" }}
                        >
                          <ErrorBoundary>
                            <InlineWidget url="https://calendly.com/aubreythreadgill" />
                          </ErrorBoundary>
                        </Card>
                      </a>
                    </div> */}
                  </Strip>
                )}
              </Suspense>
              <ScrollToHashElement />
            </div>
          </AppShell>
        </NotificationsProvider>
      </MantineProvider>
    </div>
  );
}
