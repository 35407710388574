import React, { useState, useMemo, Suspense } from "react";
import {
  BackgroundImage,
  Box,
  Card,
  Overlay,
  ScrollArea,
  Text,
  createStyles,
  // getStylesRef,
  // rem,
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faSpotify,
//   faLinkedin,
//   faFacebook,
//   faGithub,
//   faTwitter,
//   faInstagram,
//   faYoutube,
// } from "@fortawesome/free-brands-svg-icons";
import { Carousel, useAnimationOffsetEffect } from "@mantine/carousel";
import { ErrorBoundary } from "./ErrorBoundary";

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    // height: rem(280),
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],

    // [`&:hover .${getStylesRef("image")}`]: {
    //   transform: "scale(1.03)",
    // },
  },

  image: {
    ...theme.fn.cover(),
    // ref: getStylesRef("image"),
    backgroundSize: "cover",
    transition: "transform 500ms ease",
  },

  overlay: {
    position: "absolute",
    top: "20%",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage:
      "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .85) 90%)",
  },

  content: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    zIndex: 1,
  },

  title: {
    color: theme.white,
    // marginBottom: rem(5),
  },

  bodyText: {
    color: theme.colors.dark[2],
    // marginLeft: rem(7),
  },

  author: {
    color: theme.colors.dark[2],
  },
}));

export function AboutStrip({
  id,
  nav,
  startColor,
  endColor,
  title,
  titleColor,
  explainer,
  icon,
  iconColor,
  iconLink,
  children,
}) {
  const isTouch = useMemo(() => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }, []);

  const TRANSITION_DURATION = 200;

  const { classes, theme } = useStyles();

  const [embla, setEmbla] = useState(null);
  useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  const image =
    "https://images.unsplash.com/photo-1613070561201-b0dccb982856?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80";

  return (
    <div
      id={id}
      style={{
        scrollMarginTop: "65px",
      }}
    >
      <Card
        p={0}
        radius="md"
        m="md"
        sx={(theme) => ({
          // backgroundImage: theme.fn.gradient({
          //   from: startColor,
          //   to: endColor,
          //   deg: 90,
          // }),
          color: theme.white,
          boxShadow:
            "0 4px 12px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, 0.2)",
          display: "flex",
          flexDirection: "column",
          minHeight: "200px",
        })}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            minHeight: "200px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(https://images.unsplash.com/photo-1556760286-53479b954b71?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              // zIndex: -1,
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          ></div>
          <div style={{ padding: "10px", position: "relative" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexGrow: 1,
                maxHeight: "45px",
              }}
            >
              <Text
                size={28}
                color={titleColor ? titleColor : "white"}
                weight={500}
                p={0}
              >
                {title}
              </Text>
              <div className="icon-container">
                {iconLink ? (
                  <a href={iconLink} target="_blank" rel="noreferrer">
                    {icon?.type === "fa" && (
                      <FontAwesomeIcon
                        icon={icon.icon}
                        color={iconColor}
                        size="2x"
                      />
                    )}
                    {icon?.type === "emoji" && (
                      <span className="emoji-icon">{icon.icon}</span>
                    )}
                  </a>
                ) : (
                  <>
                    {icon.type === "fa" && (
                      <FontAwesomeIcon
                        icon={icon.icon}
                        color={iconColor}
                        size="2x"
                      />
                    )}
                    {icon.type === "emoji" && (
                      <span className="emoji-icon">{icon.icon}</span>
                    )}
                  </>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                color: titleColor,
              }}
            >
              {explainer}
            </div>
            <Suspense fallback={<div>Loading...</div>}>
              {nav === "scroll" ? (
                <ScrollArea type="hover" offsetScrollbars>
                  <div className="card-container-scroll">{children}</div>
                </ScrollArea>
              ) : (
                <div
                  className="card-container-carousel"
                  transitionDuration={TRANSITION_DURATION}
                >
                  <ErrorBoundary>
                    <Carousel
                      getEmblaApi={setEmbla}
                      withControls={isTouch ? false : true}
                      // withIndicators={isTouchDevice ? true : false}
                      // slideGap="xs"
                      slideSize="33.333333%"
                      breakpoints={[
                        { maxWidth: "md", slideSize: "50%", slideGap: 0 },
                        { maxWidth: "sm", slideSize: "97%", slideGap: 0 },
                      ]}
                      align="start"
                      styles={{
                        control: {
                          "&[data-inactive]": {
                            opacity: 0,
                            cursor: "default",
                          },
                        },
                      }}
                    >
                      {children}
                    </Carousel>
                  </ErrorBoundary>
                </div>
              )}
            </Suspense>
          </div>
        </div>
      </Card>
    </div>
  );
}
