import React, { useState, useMemo, Suspense } from "react";
import { BackgroundImage, Box, Card, ScrollArea, Text } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faSpotify,
//   faLinkedin,
//   faFacebook,
//   faGithub,
//   faTwitter,
//   faInstagram,
//   faYoutube,
// } from "@fortawesome/free-brands-svg-icons";
import { Carousel, useAnimationOffsetEffect } from "@mantine/carousel";
import { ErrorBoundary } from "./ErrorBoundary";

export function Strip({
  id,
  nav,
  startColor,
  endColor,
  title,
  titleColor,
  explainer,
  icon,
  iconColor,
  iconLink,
  children,
}) {
  const isTouch = useMemo(() => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }, []);

  const TRANSITION_DURATION = 200;

  const [embla, setEmbla] = useState(null);
  useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  return (
    <div
      id={id}
      style={{
        scrollMarginTop: "65px",
      }}
    >
      <Card
        // shadow="sm"
        p="xs"
        radius="md"
        // withBorder
        m="md"
        sx={(theme) => ({
          backgroundImage: theme.fn.gradient({
            from: startColor,
            to: endColor,
            deg: 135,
          }),
          boxShadow:
            "0 4px 12px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, 0.2)",
          color: theme.white,
          padding: "15px",
          display: "flex",
          flexDirection: "column",
          minHeight: "200px",
        })}
        // sx={(theme) => ({
        //   backgroundColor: "transparent",
        //   padding: "15px",
        //   display: "flex",
        //   flexDirection: "column",
        //   minHeight: "200px",
        // })}
      >
        {/* <BackgroundImage
        src="https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80"
        radius="lg"
      > */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
            maxHeight: "45px",
          }}
        >
          <Text
            size={28}
            // color={titleColor ? titleColor : "#191919"}
            color={titleColor ? titleColor : "white"} //COLOR
            // color={titleColor ? titleColor : "black"} //MINIMAL
            weight={500}
            p={0}
            // style={{ textShadow: "0em 0em 0.2em #FFFFFF" }}
          >
            {title}
          </Text>
          <div className="icon-container">
            {iconLink ? (
              <a href={iconLink} target="_blank" rel="noreferrer">
                {icon?.type === "fa" && (
                  <FontAwesomeIcon
                    icon={icon.icon}
                    color={iconColor} //COLOR
                    // color={startColor} //MINIMAL
                    size="2x"
                  />
                )}
                {icon?.type === "emoji" && (
                  <span className="emoji-icon">{icon.icon}</span>
                )}
              </a>
            ) : (
              <>
                {icon.type === "fa" && (
                  <FontAwesomeIcon
                    icon={icon.icon}
                    color={iconColor} //COLOR
                    // color={startColor}//MINIMAL
                    size="2x"
                  />
                )}
                {icon.type === "emoji" && (
                  <span className="emoji-icon">{icon.icon}</span>
                )}
              </>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            color: titleColor,
          }}
        >
          {explainer}
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          {nav === "scroll" ? (
            <ScrollArea
              type="auto"
              pb="sm"
              // offsetScrollbars
            >
              <div className="card-container-scroll-1">{children}</div>
            </ScrollArea>
          ) : nav === "scroll1" ? (
            <ScrollArea
              type="auto"
              pb="sm"
              // offsetScrollbars
            >
              <div className="card-container-scroll">{children}</div>
            </ScrollArea>
          ) : (
            <div
              className="card-container-carousel"
              transitionDuration={TRANSITION_DURATION}
            >
              <ErrorBoundary>
                <Carousel
                  getEmblaApi={setEmbla}
                  withControls={isTouch ? false : true}
                  // withIndicators={isTouchDevice ? true : false}
                  // slideGap="xs"
                  slideSize="33.333333%"
                  breakpoints={[
                    { maxWidth: "md", slideSize: "50%", slideGap: 0 },
                    { maxWidth: "sm", slideSize: "97%", slideGap: 0 },
                  ]}
                  align="start"
                  styles={{
                    control: {
                      "&[data-inactive]": {
                        opacity: 0,
                        cursor: "default",
                      },
                    },
                  }}
                >
                  {children}
                </Carousel>
              </ErrorBoundary>
            </div>
          )}
        </Suspense>
        {/* </BackgroundImage> */}
      </Card>
    </div>
  );
}
