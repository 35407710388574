import React, { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import axios from "axios";
import {
  Card,
  Text,
  Radio,
  Button,
  Notification,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  NotificationsProvider,
  showNotification,
} from "@mantine/notifications";
import { emailDefaults } from "../data";
import { owner } from "../App";
import useLocalStorage from "../hooks/useLocalStorage";

export function EmailCard({ radioValue, setRadioValue, yourEmail }) {
  // const selectedText = emailDefaults.find(
  //   (defaultText) => defaultText.role === radioValue
  // )?.text;

  const selectedText = "";
  // const [message, setMessage] = useState(selectedText);
  // const [senderName, setSenderName] = useState("");
  // const [senderEmail, setSenderEmail] = useState("");

  const contactRoles = emailDefaults.map((item) => item.role);

  const [completed, setCompleted] = useLocalStorage("completed", false);
  const [completedSenderName, setCompletedSenderName] = useLocalStorage(
    "completedSenderName",
    ""
  );
  const completedSenderFirstName = completedSenderName.split(" ")[0];
  const [completedSenderEmail, setCompletedSenderEmail] = useLocalStorage(
    "completedSenderEmail",
    ""
  );
  const [completedMessage, setCompletedMessage] = useLocalStorage(
    "completedMessage",
    ""
  );
  const [completedTime, setCompletedTime] = useLocalStorage(
    "completedTime",
    ""
  );
  //Create a constant for minutesAgo that shows the completedTime, which is a localeString, and subtract the current time, then show the difference in minutes.
  const minutesAgo = Math.floor(
    (new Date().getTime() - new Date(completedTime).getTime()) / 60000
  );

  const emailJsForm = useRef();
  const contactForm = useForm({
    initialValues: {
      to: owner.email,
      senderName: "",
      senderEmail: "",
      message: "",
    },

    validate: {
      senderName: (value) => {
        if (!value) {
          return "Please enter your name";
        }
      },
      senderEmail: (value) => {
        if (!value) {
          return "Please enter your email address";
        }
        if (value) {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
          if (!emailRegex.test(value)) {
            return "Please enter a valid email address";
          }
        }
      },
      message: (value) => {
        if (!value) {
          return "Please enter a message";
        }
      },
    },
  });

  // useEffect(() => {
  //   const senderName = contactForm.values.senderName;
  //   const senderFirstName = senderName.split(" ")[0];
  //   const salutation = `Hi ${owner.firstName}, `;
  //   const signature = `\n\nBest,\n${senderFirstName}`;
  //   if (senderName.length > 0) {
  //     contactForm.setFieldValue(
  //       "message",
  //       salutation + selectedText + signature
  //     );
  //     console.log("render", senderName);
  //     return;
  //   } else {
  //     contactForm.setFieldValue("message", salutation + selectedText);
  //   }
  // }, [radioValue, contactForm.values.senderName]);

  const sendEmail = () => {
    emailjs
      .sendForm(
        "service_ve9gcbb",
        "template_nqwwwth",
        emailJsForm.current,
        "7u-WS83fjYbTpw8CU"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  function handleFormSubmit(e) {
    e?.preventDefault();
    contactForm.validate();
    setCompleted(true);
    setCompletedSenderName(contactForm.values.senderName);
    setCompletedSenderEmail(contactForm.values.senderEmail);
    setCompletedMessage(contactForm.values.message);
    setCompletedTime(new Date().toLocaleTimeString());
    sendEmail();
    contactForm.reset();
    showNotification({
      title: "Email sent!",
      message: "Thank you for reaching out!",
      // color: "green",
      // icon: <IconCheck size={18} />,
      autoClose: 4000,
    });
  }

  return (
    <div className="card-lg">
      {!completed ? (
        <Card
          shadow="sm"
          p="xs"
          radius="md"
          withBorder
          // style={{ maxHeight: "490px", minWidth: "280px" }}
        >
          {/* <Radio.Group
            value={radioValue}
            onChange={setRadioValue}
            name="email"
            orientation="horizontal"
            size="md"
            label="Feel free to reach out if you are a(n):"
            //   description="This is anonymous"
            spacing={4}
          >
            {contactRoles.map((item) => {
              return (
                <Radio
                  size="md"
                  p={5}
                  value={item}
                  label={item}
                  styles={{
                    label: {
                      paddingLeft: "5px",
                    },
                  }}
                />
              );
            })}
          </Radio.Group> */}
          <form
            ref={emailJsForm}
            onSubmit={contactForm.onSubmit(() => handleFormSubmit())}
          >
            {/* <TextInput
            id="to_name"
            value="Aubrey (aubrey.threadgill@gmail.com)"
            label="To:"
            disabled
          /> */}
            <TextInput
              name="from_name"
              placeholder="Your name"
              label="From:"
              size="md"
              mb="xs"
              autoCapitalize="words"
              withAsterisk
              style={{
                autoComplete: "email",
              }}
              {...contactForm.getInputProps("senderName")}
            />
            <TextInput
              name="reply_to"
              placeholder="Your email address"
              type="email"
              size="md"
              withAsterisk
              style={{
                autoComplete: "email",
              }}
              {...contactForm.getInputProps("senderEmail")}
            />
            <Textarea
              name="message"
              placeholder="Insert your message here..."
              label="Message:"
              mt={5}
              size="md"
              minRows={6}
              withAsterisk
              {...contactForm.getInputProps("message")}
            />

            <Button
              variant="light"
              color="blue"
              fullWidth
              mt="md"
              radius="md"
              type="submit"
            >
              Send
            </Button>
          </form>
        </Card>
      ) : (
        <Card
          shadow="sm"
          p="xs"
          radius="md"
          withBorder
          style={{ maxHeight: "490px", minWidth: "280px", maxWidth: "600px" }}
        >
          <div>
            <Text style={{ paddingRight: "5px" }}>
              Thanks for reaching out, {completedSenderFirstName}. I've received
              your email and will do my best to get back to you within 24 hours.
              <br></br>
              <br></br>-{owner.firstName}
            </Text>
          </div>
        </Card>
      )}
    </div>
  );
}
