import React from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { SkeletonCard } from "./SkeletonCard";

export function Tweet({ tweetId }) {
  return (
    <div style={{ minWidth: "280px" }}>
      <TwitterTweetEmbed
        placeholder={<SkeletonCard />}
        options={{
          maxWidth: 350,
          width: 300,
        }}
        tweetId={tweetId}
      />
    </div>
  );
}
