const languageColors = {
  HTML: "#e34c26",
  JavaScript: "#f1e05a",
  CSS: "#563d7c",
  TypeScript: "#3178c6",
  "C#": "#178600",
  "C++": "#f34b7d",
  C: "#555555",
  Python: "#3572A5",
  Java: "#b07219",
  PHP: "#4F5D95",
  Ruby: "#701516",
  Go: "#00ADD8",
  Swift: "#ffac45",
  Kotlin: "#F18E33",
  Rust: "#dea584",
  Scala: "#c22d40",
  Shell: "#89e051",
  "Objective-C": "#438eff",
  Perl: "#0298c3",
  Haskell: "#5e5086",
  Lua: "#000080",
  Clojure: "#db5855",
  Groovy: "#e69f56",
  "Jupyter Notebook": "#DA5B0B",
  "Visual Basic": "#945db7",
  CoffeeScript: "#244776",
};

export function GitHubLanguageIcons({ languages }) {
  return (
    <div>
      {languages.map((language, index) => {
        const languageColor = languageColors[language] || "#000000";

        return (
          <span key={index} className="repo-language">
            <span
              className="repo-language-color"
              style={{ backgroundColor: languageColor }}
            ></span>
            <span className="language-text">{language}</span>
          </span>
        );
      })}
    </div>
  );
}
