// import { useState, useEffect } from "react";
import { Anchor, Card, Image, Text, Flex } from "@mantine/core";
// import ogs from "open-graph-scraper";

export function WritingCard({
  image,
  link,
  title,
  favicon,
  publication,
  author,
}) {
  // const [ogData, setOgData] = useState("");
  // const ogs = require("open-graph-scraper");

  // Use npm open-graph-scraper to get og data once on initial page load
  // useEffect(() => {
  //   const options = { url: link };
  //   ogs(options, (error, results) => {
  //     if (error) {
  //       console.log("error", error);
  //     } else {
  //       setOgData(results.data);
  //     }
  //   });
  // }, []);

  return (
    <div className="card">
      <Card shadow="sm" p="xs" radius="md" withBorder>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Image
            width={70}
            height={70}
            radius="md"
            // fit="contain"
            src={image}
            alt={title}
            withPlaceholder
            imageProps={{
              loading: "lazy",
            }}
          />
          <div style={{ paddingLeft: "10px" }}>
            <Anchor
              color="dark"
              href={link}
              target="_blank"
              weight={500}
              mb="sm"
            >
              {title}
            </Anchor>
            <Flex>
              <Image
                src={favicon}
                width={14}
                height={14}
                // fit="contain"
                alt={title}
                withPlaceholder
                pr={4}
                mt={4}
                mr={2}
                imageProps={{
                  loading: "lazy",
                }}
              />
              <Text color="dimmed" size="sm">
                {author}
              </Text>
            </Flex>
          </div>
        </div>
      </Card>
    </div>
  );
}
