export function SpotifyCard({
  link,
  wide,
  tall,
  width = wide ? "100%" : 300,
  height = tall ? 352 : 152,
}) {
  const url = new URL(link);

  //352px height is a  Spotify-designated breakpoint. It will shrink below this height down to 142px.

  return (
    <iframe
      title="Spotify Web Player"
      src={`https://open.spotify.com/embed${url.pathname}`}
      width={width}
      height={height}
      allow="encrypted-media"
      style={{
        borderWidth: "0px",
      }}
    />
  );
}
