import React, { useState } from "react";
import { Card, Skeleton, Text } from "@mantine/core";

export function YouTubeCard({ youTubeUrl, comment }) {
  let url = new URL(youTubeUrl);
  const id = url.searchParams.get("v") || url.pathname.split("/").pop();
  const startTime = url.searchParams.get("t");
  const startSlug = startTime ? `?start=${startTime}` : "";
  const embedUrl = `https://www.youtube-nocookie.com/embed/${id}${startSlug}`;

  const [loading, setLoading] = useState(true);

  return (
    <div className="card-lg">
      <Card shadow="sm" p={4} radius="md" withBorder>
        <Skeleton visible={loading}>
          <div className="video-responsive">
            <iframe
              onLoad={() => setLoading(false)}
              width="853"
              height="480"
              src={embedUrl}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video"
              loading="lazy"
            />
          </div>
        </Skeleton>
        <Text size="sm" color="dimmed" style={{ whiteSpace: "pre-line" }}>
          {comment}
        </Text>
      </Card>
    </div>
  );
}
